import { formatNumber } from "common/formatNumber";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        gap: 5,
    },
    box: props => ({
        height: 10,
        width: 10,
        backgroundColor: props.isUniques ? theme.palette.primary.secondGraphColor : theme.palette.primary.main,
    }),
}));

export const MonthTotal = ({ total, isUniques }) => {
    const text = isUniques ? t("screenHome.openFeaturesCountsChart.totalUniqueEntries") : t("screenHome.openFeaturesCountsChart.totalEntries");
    const classes = useStyles({ isUniques });
    return (
        <div className={classes.container}>
            <div className={classes.box}></div>
            <Typography>{` ${text}: ${formatNumber(total)}`}</Typography>
        </div>
    );
};
