import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import MUIDataTable from "mui-datatables";
import { isShowPickUpInfo } from "../store/logic/isShowPickUpInfo";
import { t } from "common/localization/translate";
import { useScreenPurchaseUsersInfosStore } from "../store";
import { getTableDataOptions } from "screens/organization/purchases/store/logic/getTableDataOptions";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";

export const PurchaseUsersInfoTable = observer(() => {
    const store = useScreenPurchaseUsersInfosStore();
    const { currentSegment } = store.rootStore.userInfoStore || {};
    const { segmentId } = toJS(currentSegment) || {};
    const { purchasesUsersInfoTableData: data, isFetching, segmentCouponPurchasersInfo } = store;
    const isWithSegmentName = segmentCouponPurchasersInfo && segmentCouponPurchasersInfo.length > 0 ? segmentCouponPurchasersInfo[0].segmentName : null;
    const isShowCodeOrOrderIdCoupon = segmentCouponPurchasersInfo && segmentCouponPurchasersInfo.length > 0 ? segmentCouponPurchasersInfo[0].couponCode : null;
    const { title, options, columns } = getTableData(isFetching, isWithSegmentName, isShowCodeOrOrderIdCoupon, segmentId);
    return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
});

const getTableData = (isFetching, isWithSegmentName, isShowCodeOrOrderIdCoupon, segmentId) => {
    const { firstColumnOptions, tableOptions } = getTableDataOptions();
    const policeSegmentName = t("police.segment.name");
    const columns = [
        {
            name: "couponTitle",
            label: t("screenPurchaseUsersInfo.table.col.couponTitle"),
            options: firstColumnOptions,
        },
        {
            name: "identifier",
            label: t("screenPurchaseUsersInfo.table.col.identifier"),
            options: tableOptions,
        },
        {
            name: "phone",
            label: t("screenPurchaseUsersInfo.table.col.phone"),
            options: tableOptions,
        },
        {
            name: "userName",
            label: t("screenPurchaseUsersInfo.table.col.userName"),
            options: tableOptions,
        },
        { name: "date", label: t("screenPurchaseUsersInfo.table.col.date"), options: tableOptions },
    ];

    if (segmentId == segmentIds.OVDIM_BARI_SEGMENT_ID) {
        columns.push({ name: "isUsed", label: t("screenPurchaseUsersInfo.table.col.isUsed"), options: tableOptions });
        columns.push({ name: "priceForOvdim", label: t("screenPurchaseUsersInfo.table.col.costToOvdim"), options: tableOptions });
        columns.push({ name: "coins", label: t("screenPurchaseUsersInfo.table.col.coins"), options: tableOptions });
    }

    if (isWithSegmentName || (segmentId && segmentId.name === policeSegmentName)) {
        columns.push({ name: "segmentName", label: t("screenPurchaseUsersInfo.table.col.segmentName"), options: tableOptions });
    }

    if (isShowCodeOrOrderIdCoupon) {
        columns.splice(1, 0, { name: "couponCodeTitle", label: t("screenPurchaseUsersInfo.table.col.couponCodeTitle"), options: tableOptions });
    }

    if (isShowPickUpInfo(segmentId)) {
        columns.splice(1, 0, { name: "pickUpPoint", label: t("screenPurchaseUsersInfo.table.col.pickUpPoint"), options: tableOptions });
    }

    const options = {
        filter: false,
        download: true,
        onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(data);
        },
        downloadOptions: { filename: "usersPurchases.csv" },
        print: false,
        viewColumns: false,
        responsive: "scrollMaxHeight",
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isFetching ? "Loading..." : "Sorry, no matching records found",
            },
        },
    };
    const title = t("screenPurchaseUsersInfo.table.title");
    return { title, options, columns };
};
