import { isShowPickUpInfo } from "./isShowPickUpInfo";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";
import { t } from "common/localization/translate";

export function getPurchasesUsersInfoTableData(store, segmentId) {
    const { segmentCouponPurchasersInfo } = store;
    const noData = t("screenPurchaseUsersInfo.table.noData");

    const tableDataArray = segmentCouponPurchasersInfo
        ? segmentCouponPurchasersInfo.map(item => {
              const array = [item.couponTitle, item.userIdentifier, item.phone, item.userName, item.date];
              if (segmentId == segmentIds.OVDIM_BARI_SEGMENT_ID) {
                  const isUsed = item.isUsed ? t("screenPurchaseUsersInfo.table.col.isUsedTrue") : t("screenPurchaseUsersInfo.table.col.isUsedFalse");
                  array.push(isUsed);
                  array.push(item.costToOvdim ? `${item.costToOvdim}₪` : noData);
                  array.push(item.coins ? item.coins : noData);
              }
              if (item.segmentName) {
                  array.push(item.segmentName);
              }

              if (item.couponCode) {
                  array.splice(1, 0, item.couponCode);
              }

              if (isShowPickUpInfo(segmentId)) {
                  array.splice(1, 0, item.pickUpPoint);
              }

              return array;
          })
        : [];
    return tableDataArray;
}
