import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getScreenData } from "./server/getScreenData";
import {
  getPeriodsForNewUsersChart,
  specialPeriodTypes,
} from "./logic/getPeriodsForNewUsersChart";
import { getBonusesForChart } from "./logic/getBonusesForChart";
import find from "lodash/find";
import { getMeausermentsForChart } from "./logic/getMeausermentsForChart";

const ScreenHomeStoreContext = React.createContext({});
export const ScreenHomeStoreProvider = ScreenHomeStoreContext.Provider;
export const useScreenHomeStore = () =>
  React.useContext(ScreenHomeStoreContext);

class ScreenHomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
    makeObservable(this, {
      isFetching: observable,
      isFetchingForSmallSpinner: observable,
      isFetchingSegmentImprovement: observable,
      isFetchingTodayGainedCoins: observable,
      countNewUsersInSegment: observable,
      activeUsersCountGraphData: observable,
      totalCurrentSegmentMembers: observable,
      sumOfCoinsOfCurrentSegmentUsers: observable,
      segmentUsersUsingBonusesTotals: observable,
      todayGainedCoins: observable,
      monthlyImprovement: observable,
      weeklyImprovement: observable,
      referralLeaders: observable,
      periodOptions: observable,
      currentPeriodIndexNewUsers: observable,
      currentPeriodIndexAppOpen: observable,
      currentPeriodIndexActiveUsers: observable,
      lastWeekNewUsers: observable,
      selectedSegmentIdToShow: observable,
      segmentUsersUsingMeasurements: observable,
      totalUsersToDemandPayment: observable,
      formattedAppOpenData: observable,
      date: observable,
      featuresOpens: observable,
      arrayOfBonusesForChart: computed,
      selectedSegmentName: computed,
      getScreenData: action.bound,
      initProperties: action.bound,
      setCurrentPeriodIndexNewUsers: action.bound,
      setCurrentPeriodIndexAppOpen: action.bound,
      setcurrentPeriodIndexActiveUsers: action.bound,
      setCountNewUsersInSegment: action.bound,
      setActiveUsersCountGraphData: action.bound,
      setTotalCurrentSegmentMembers: action.bound,
      setIsFetchingForSmallSpinner: action.bound,
      setIsFetching: action.bound,
      setLastWeekNewUsers: action.bound,
      setReferralLeaders: action.bound,
      setSumOfCoinsOfCurrentSegmentUsers: action.bound,
      setSelectedSegmentIdToShow: action.bound,
    });
  }

  initProperties() {
    this.date = null;
    this.formattedAppOpenData = null;
    this.totalUsersToDemandPayment = 0;
    this.segmentUsersUsingMeasurements = null;
    this.selectedSegmentIdToShow = null;
    this.lastWeekNewUsers = null;
    this.referralLeaders = null;
    this.weeklyImprovement = 0;
    this.monthlyImprovement = 0;
    this.todayGainedCoins = 0;
    this.segmentUsersUsingBonusesTotals = null;
    this.sumOfCoinsOfCurrentSegmentUsers = 0;
    this.totalCurrentSegmentMembers = 0;
    this.activeUsersCountGraphData = null;
    this.countNewUsersInSegment = 0;
    this.isFetchingTodayGainedCoins = false;
    this.isFetchingSegmentImprovement = false;
    this.isFetchingForSmallSpinner = false;
    this.isFetching = true;
    this.periodOptions = getPeriodsForNewUsersChart();
    this.currentPeriodIndexNewUsers = 2;
    this.currentPeriodIndexAppOpen = 2;
    this.currentPeriodIndexActiveUsers = 4;
    this.featuresOpens = null;
  }

  getScreenData = async (isWithSmallSpinner) => {
    this.setIsFetching(isWithSmallSpinner ? false : true);
    this.setIsFetchingForSmallSpinner(true);
    const segmentId = this.rootStore.userInfoStore.currentSegmentId;
    const periodNewUsers =
      this.periodOptions[this.currentPeriodIndexNewUsers].type;
    const periodAppOpen =
      this.periodOptions[this.currentPeriodIndexAppOpen].type;
    const periodActiveUsers = specialPeriodTypes.LAST_NINETY_DAYS;
    const {
      countNewUsersInSegment,
      totalCurrentSegmentMembers,
      sumOfCoinsOfCurrentSegmentUsers,
      referralLeaders,
      lastWeekNewUsers,
      activeUsersCountGraphData,
      numberOfUsersToDemandPayment,
      date,
      formattedAppOpenData,
      yesterdayGainedCoins,
      monthlyImprovement,
      weeklyImprovement,
      segmentUsersUsingBonusesTotals,
      segmentUsersUsingMeasurements,
      featuresOpens,
    } = await getScreenData(
      segmentId,
      periodNewUsers,
      periodAppOpen,
      periodActiveUsers
    );
    this.totalUsersToDemandPayment = numberOfUsersToDemandPayment;
    this.segmentUsersUsingBonusesTotals = segmentUsersUsingBonusesTotals;
    this.segmentUsersUsingMeasurements = segmentUsersUsingMeasurements;
    this.date = date;
    this.setCountNewUsersInSegment(countNewUsersInSegment);
    this.setActiveUsersCountGraphData(activeUsersCountGraphData);
    this.setTotalCurrentSegmentMembers(totalCurrentSegmentMembers);
    this.setSumOfCoinsOfCurrentSegmentUsers(sumOfCoinsOfCurrentSegmentUsers);
    this.setReferralLeaders(referralLeaders);
    this.setLastWeekNewUsers(lastWeekNewUsers);
    this.formattedAppOpenData = formattedAppOpenData;
    this.todayGainedCoins = yesterdayGainedCoins;
    this.monthlyImprovement = monthlyImprovement;
    this.weeklyImprovement = weeklyImprovement;
    this.setIsFetching(false);
    this.setIsFetchingForSmallSpinner(false);
    this.featuresOpens = featuresOpens;
  };

  setActiveUsersCountGraphData(activeUsersCountGraphData) {
    this.activeUsersCountGraphData = activeUsersCountGraphData;
  }

  setCurrentPeriodIndexNewUsers(index) {
    this.currentPeriodIndexNewUsers = index;
    this.getScreenData(true);
  }

  setCurrentPeriodIndexAppOpen(index) {
    this.currentPeriodIndexAppOpen = index;
    this.getScreenData(true);
  }

  setcurrentPeriodIndexActiveUsers(index) {
    this.currentPeriodIndexActiveUsers = index;
    this.getScreenData(true);
  }

  setCountNewUsersInSegment(countNewUsersInSegment) {
    this.countNewUsersInSegment = countNewUsersInSegment;
  }

  setTotalCurrentSegmentMembers(totalCurrentSegmentMembers) {
    this.totalCurrentSegmentMembers = totalCurrentSegmentMembers;
  }

  setSumOfCoinsOfCurrentSegmentUsers(sumOfCoinsOfCurrentSegmentUsers) {
    this.sumOfCoinsOfCurrentSegmentUsers = sumOfCoinsOfCurrentSegmentUsers;
  }

  setReferralLeaders(referralLeaders) {
    this.referralLeaders = referralLeaders;
  }

  setLastWeekNewUsers(lastWeekNewUsers) {
    this.lastWeekNewUsers = lastWeekNewUsers;
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  setIsFetchingForSmallSpinner(isFetching) {
    this.isFetchingForSmallSpinner = isFetching;
  }

  setSelectedSegmentIdToShow(segmentId) {
    const currentSegment = this.rootStore.userInfoStore.currentSegmentId;
    currentSegment.segmentIds = segmentId
      ? [segmentId]
      : this.rootStore.userInfoStore.currentSegment.multiSegmentOptions.map(
          (segment) => segment.id
        );
    this.selectedSegmentIdToShow = segmentId;
  }

  get arrayOfBonusesForChart() {
    return getBonusesForChart(this);
  }

  get arrayOfMeausermentsForChart() {
    return getMeausermentsForChart(this);
  }

  get selectedSegmentName() {
    if (!this.rootStore.userInfoStore.currentSegment) {
      return "";
    }

    const { segmentName } = this.rootStore.userInfoStore.currentSegment;
    if (!this.selectedSegmentIdToShow) {
      return segmentName;
    }
    const { userInfoStore } = this.rootStore;
    const { currentSegment: currentSegmentStore } = userInfoStore;
    const segment = find(
      currentSegmentStore.multiSegmentOptions,
      (item) => item.id === this.selectedSegmentIdToShow
    );
    return segment.name;
  }
}

export function createScreenHomeStore(rootStore) {
  const store = new ScreenHomeStore(rootStore);
  return store;
}
