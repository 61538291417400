import { MonthTotal } from "./monthTotal";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        marginTop: 20,
    },
}));
export const MonthTotals = ({ totalCount, totalCountUniques }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <MonthTotal total={totalCount} />
            <MonthTotal total={totalCountUniques} isUniques={true} />
        </div>
    );
};
