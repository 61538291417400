import React from "react";
import { makeObservable, action, flow, observable, computed } from "mobx";
import { getScreenData as getScreenDataServer } from "./server/getScreenData";
import { getPurchasesUsersInfoTableData as getPurchasesUsersInfoTableDataLogic } from "./logic/getPurchasesUsersInfoTableData";
import { getNowInMoment, subtractFromDate } from "common/dateAndTime/moment";

const ScreenPurchaseUsersInfoStoreContext = React.createContext({});
export const ScreenPurchaseUsersInfosStoreProvider = ScreenPurchaseUsersInfoStoreContext.Provider;
export const useScreenPurchaseUsersInfosStore = () => React.useContext(ScreenPurchaseUsersInfoStoreContext);

class ScreenPurchaseUsersInfoStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
        makeObservable(this, {
            isFetching: observable,
            segmentCouponPurchasersInfo: observable,
            startDate: observable,
            endDate: observable,
            purchasesUsersInfoTableData: computed,
            initProperties: action.bound,
            getScreenData: action.bound,
            setSegmentCouponPurchasersInfo: action.bound,
            setStartDate: action.bound,
            setEndDate: action.bound,
        });
    }

    initProperties() {
        this.isFetching = true;
        this.segmentCouponPurchasersInfo = null;
        this.endDate = getNowInMoment();
        this.startDate = subtractFromDate(this.endDate, 7, "days");
    }

    getScreenData = flow(function* () {
        this.isFetching = true;
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        this.setSegmentCouponPurchasersInfo(null);
        const segmentCouponPurchasersInfo = yield getScreenDataServer(segmentId, this.startDate, this.endDate);
        this.setSegmentCouponPurchasersInfo(segmentCouponPurchasersInfo);
        this.isFetching = false;
    });

    setSegmentCouponPurchasersInfo(segmentCouponPurchasersInfo) {
        this.segmentCouponPurchasersInfo = segmentCouponPurchasersInfo;
    }

    setStartDate(startDate) {
        this.startDate = startDate;
    }

    setEndDate(endDate) {
        this.endDate = endDate;
    }

    get purchasesUsersInfoTableData() {
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        return getPurchasesUsersInfoTableDataLogic(this, segmentId);
    }
}

export function createScreenPurchaseUsersInfoStore(rootStore) {
    const store = new ScreenPurchaseUsersInfoStore(rootStore);
    return store;
}
