import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

export const RightButton = ({ hadleClick, isDisabled }) => {
    return (
        <IconButton onClick={hadleClick} disabled={isDisabled}>
            <ChevronRight />
        </IconButton>
    );
};
