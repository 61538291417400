import React from "react";
import { Typography, Box } from "@mui/material";
import { RightButton } from "./RightButton";
import { LeftButton } from "./LeftButton";

export const ChangeMonthTabs = ({ paginationIdx, onSetPagination, paginationDates }) => {
    const currentDateToShow = paginationDates[paginationIdx].dateToShowInPagination;
    const isDisabledRight = paginationIdx <= 0;
    const isDisabledLeft = paginationIdx >= paginationDates.length - 1;

    const handlePrev = () => {
        onSetPagination(prevIdx => prevIdx + 1);
    };
    const handleNext = () => {
        onSetPagination(prevIdx => prevIdx - 1);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
            <RightButton hadleClick={handleNext} isDisabled={isDisabledRight} />
            <Typography>{currentDateToShow}</Typography>
            <LeftButton handleClick={handlePrev} isDisabled={isDisabledLeft} />
        </Box>
    );
};
