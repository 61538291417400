import { IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

export const LeftButton = ({ handleClick, isDisabled }) => {
    return (
        <IconButton onClick={handleClick} disabled={isDisabled}>
            <ChevronLeft />
        </IconButton>
    );
};
