export const tableDataOptions = {
    customBodyRender: value => <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>{value}</div>,
    setCellHeaderProps: value => ({ style: { textAlign: "right", padding: 0, whiteSpace: "nowrap" } }),
    customHeadLabelRender: ({ index, ...column }) => <div style={{ color: "#546e7a", fontSize: "12px", fontWeight: 500 }}>{column.label}</div>,
};

export const tableDataOptionsEnglish = {
    customBodyRender: value => <div style={{ textAlign: "left", whiteSpace: "nowrap" }}>{value}</div>,
    setCellHeaderProps: value => ({ style: { textAlign: "left", padding: 0, whiteSpace: "nowrap" } }),
    customHeadLabelRender: ({ index, ...column }) => <div style={{ color: "#546e7a", fontSize: "12px", fontWeight: 500, marginLeft: 15 }}>{column.label}</div>,
};

export const firstColumnDataOptions = {
    customBodyRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
    setCellProps: () => ({
        style: {
            whiteSpace: "wrap",
            minWidth: 200,
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 100,
        },
    }),
    setCellHeaderProps: () => ({
        style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 101,
            textAlign: "right",
            padding: 0,
        },
    }),
    customHeadLabelRender: ({ index, ...column }) => <div style={{ color: "#546e7a", fontSize: "12px", fontWeight: 500 }}>{column.label}</div>,
};

export const firstColumnDataOptionsEnglish = {
    customBodyRender: value => <div style={{ textAlign: "left" }}>{value}</div>,
    setCellProps: () => ({
        style: {
            whiteSpace: "wrap",
            minWidth: 200,
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 100,
        },
    }),
    setCellHeaderProps: () => ({
        style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
            textAlign: "left",
        },
    }),
    customHeadLabelRender: ({ index, ...column }) => <div style={{ color: "#546e7a", fontSize: "12px", fontWeight: 500 }}>{column.label}</div>,
};
