import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenPurchaseUsersInfoStore, ScreenPurchaseUsersInfosStoreProvider } from "./store";
import DatePicker from "common/ui/datePickerV2";
import { t } from "common/localization/translate";
import { PurchaseUsersInfoTable } from "./components/purchasesUsersInfoTable";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { segmentsPaths } from "../../../commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { getIsRtl } from "utils/direction/getIsRtl";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
        },
    },
    row: {
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    toolTipIcon: {
        color: theme.palette.primary.main,
        marginLeft: props => (props.isRtl ? 0 : 15),
        marginRight: props => (props.isRtl ? 15 : 0),
        fontSize: "25px",
    },
    toolTipGrid: {
        marginRight: props => (props.isRtl ? 0 : "auto"),
        marginLeft: props => (props.isRtl ? "auto" : 0),
    },
    header: {
        display: "flex",
        flexDirection: "row",
    },
    title: {
        fontSize: "25px",
        marginTop: 5,
    },
}));

export const PurchaseUsersInfo = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenPurchaseUsersInfoStore(rootStore));
    const isRtl = getIsRtl();
    const classes = useStyles({ isRtl });

    useEffect(() => {
        if (rootStore.userInfoStore.currentSegment && rootStore.userInfoStore.currentSegment.segmentId) {
            store.getScreenData();
        }
    }, []);

    const onChangeStartDate = startDate => {
        store.setStartDate(startDate);
        store.getScreenData();
    };

    const onChangeEndDate = endDate => {
        store.setEndDate(endDate);
        store.getScreenData();
    };
    const segmentId = rootStore.userInfoStore.currentSegmentId;

    const screenToolTip =
        segmentId != segmentsPaths.CLALIT.id ? (
            <div>
                {t("organizationDashboard.purchasesUsersInfo.tooltip1")} <br />
                {t("organizationDashboard.purchasesUsersInfo.tooltip2")} <br />
            </div>
        ) : (
            <div>{t("organizationDashboard.purchasesUsersInfo.tooltip1")}</div>
        );

    return (
        <ScreenPurchaseUsersInfosStoreProvider value={store}>
            <Page className={classes.root} title={t("screenPurchaseUsersInfo.table.title")}>
                <Grid className={classes.header} item lg={6} xs={12}>
                    <Typography className={classes.title} component="h2" gutterBottom variant="overline">
                        {t("screenPurchaseUsersInfo.table.title")}
                    </Typography>
                    <Grid className={classes.toolTipGrid}>
                        <Tooltip title={screenToolTip} classes={classes}>
                            <HelpIcon className={classes.toolTipIcon} />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container lg={12} sm={12} className={classes.row}>
                    <Grid item lg={3} sm={12} className={classes.row}>
                        <DatePicker onChangeEndDate={onChangeEndDate} onChangeStartDate={onChangeStartDate} />
                    </Grid>
                </Grid>
                <PurchaseUsersInfoTable />
            </Page>
        </ScreenPurchaseUsersInfosStoreProvider>
    );
});
