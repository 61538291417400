import { Tabs, Tab, Box } from "@mui/material";
import { t } from "common/localization/translate";
import { featuresOptionsValues } from "../logic/featuresOptionsValues";

export const FeaturesOptionsMenu = ({ onChangeFeature, onSelectedFeature }) => {
    const featuresOptions = [
        { label: t("screenHome.openFeaturesCountsChart.articles"), value: featuresOptionsValues.ARTICLES },
        { label: t("screenHome.openFeaturesCountsChart.water"), value: featuresOptionsValues.WATER },
        { label: t("screenHome.openFeaturesCountsChart.videosMain"), value: featuresOptionsValues.VIDEOS_MAIN },
        { label: t("screenHome.openFeaturesCountsChart.stepsTranings"), value: featuresOptionsValues.STEPS_TRANINGS },
    ];

    const featuresTabs = featuresOptions.map(({ label, value }) => {
        return <Tab label={label} value={value} key={value} style={value == onSelectedFeature ? { color: "#ffffff" } : null} />;
    });

    const handleChange = (event, newValue) => {
        onChangeFeature(newValue);
    };

    return (
        <Box sx={{ marginRight: "15px" }}>
            <Tabs
                value={onSelectedFeature}
                onChange={handleChange}
                sx={{
                    "& .MuiTab-root": {
                        borderRadius: "25px",
                        border: "1px solid #3587f2",
                        margin: "5px",
                        color: "#3587f2",
                        fontSize: "16px",
                    },
                    "& .Mui-selected": {
                        color: "#ffffff",
                        backgroundColor: "#3587f2",
                    },
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                }}
            >
                {featuresTabs}
            </Tabs>
        </Box>
    );
};
