import { Tabs, Tab, Box } from "@mui/material";
import { t } from "common/localization/translate";
import { videosOpionsValues } from "../logic/videosOptionsValues";

export const TraningVideosOptionsMenu = ({ onChangeVideoCategory, onSelectedVideoCategory }) => {
    const videosOptions = [
        { label: t("screenHome.openFeaturesCountsChart.videosMain.homeScreen"), value: videosOpionsValues.HOME_SCREEN },
        { label: t("screenHome.openFeaturesCountsChart.videosMain.traningVideos"), value: videosOpionsValues.TRAINING_VIDEOS },
        { label: t("screenHome.openFeaturesCountsChart..videosMain.roniVideos"), value: videosOpionsValues.RONI_VIDEOS },
        { label: t("screenHome.openFeaturesCountsChart.videosMain.maindfulness"), value: videosOpionsValues.MAINDFULNESS },
    ];

    const videoTabs = videosOptions.map(({ label, value }) => {
        return <Tab label={label} value={value} key={value} />;
    });

    const handleChange = (event, newValue) => {
        onChangeVideoCategory(newValue);
    };

    return (
        <Box sx={{ marginRight: "15px" }}>
            <Tabs
                value={onSelectedVideoCategory}
                onChange={handleChange}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: "16px",
                        textDecoration: "underline",
                    },
                    "& .Mui-selected": {
                        color: "#3587f2",
                    },
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                }}
            >
                {videoTabs}
            </Tabs>
        </Box>
    );
};
