import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import WaveChart from "common/ui/waveChart";
import { t } from "common/localization/translate";
import { ChangeMonthTabs } from "./components/changeMonthTabs";
import { FeaturesOptionsMenu } from "./components/featuresOptions";
import { TraningVideosOptionsMenu } from "./components/traningVideosOptions";
import { useScreenHomeStore } from "../../store";
import { getSelectedFeatureData } from "./logic/getSelectedeFeatureData";
import { segmentsPaths } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { MonthTotals } from "./components/monthTotals";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
    },
    header: { flexDirection: "row", display: "flex" },
    inner: {
        height: 375,
        minWidth: 500,
    },
    chart: {
        height: "100%",
    },
}));

export const FeaturesEntriesChart = observer(() => {
    const classes = useStyles();
    const store = useScreenHomeStore();
    const [paginationIdx, setPaginationIdx] = useState(0);
    const [selectedFeature, setSelectedFeature] = useState("articles");
    const [selectedVideoCategory, setSelectedVideoCategory] = useState("videosMain");

    const isOvdim = store.rootStore.userInfoStore.currentSegmentId == segmentsPaths.OVDIM_BARI.id;
    if (!isOvdim || !store.featuresOpens) return null;

    const { paginationDates, data } = store.featuresOpens;
    const isVideoCategory = selectedFeature === "videosMain";
    const dateToShow = paginationDates[paginationIdx].dateToIndex;

    const { counts, countsDistinct, labels, totalMonthCount, totalMonthCountDistinct } = getSelectedFeatureData({ isVideoCategory, data, dateToShow, selectedFeature, selectedVideoCategory });

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header} action={<ChangeMonthTabs paginationIdx={paginationIdx} onSetPagination={setPaginationIdx} paginationDates={paginationDates} />} title={t("screenHome.openFeaturesCountsChart.title")} />
            <FeaturesOptionsMenu onChangeFeature={setSelectedFeature} onSelectedFeature={selectedFeature} />
            {isVideoCategory && <TraningVideosOptionsMenu onChangeVideoCategory={setSelectedVideoCategory} onSelectedVideoCategory={selectedVideoCategory} />}
            <Divider />
            <MonthTotals totalCount={totalMonthCount} totalCountUniques={totalMonthCountDistinct} />
            <CardContent>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <WaveChart
                            className={classes.chart}
                            labels={labels}
                            linesData={[
                                {
                                    dataArray: counts,
                                    textLabel: t("screenHome.openAppCountsChart.labelValueType"),
                                },
                                {
                                    dataArray: countsDistinct,
                                    textLabel: t("screenHome.openAppCountsChart.labelValueTypeDistinct"),
                                },
                            ]}
                        />
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
});
