export function getSelectedFeatureData({ isVideoCategory, data, dateToShow, selectedFeature, selectedVideoCategory }) {
    if (!data[dateToShow]) {
        const emptyMonthData = {
            counts: [],
            countsDistinct: [],
            labels: [],
            totalMonthCount: 0,
            totalMonthCountDistinct: 0,
        };
        return emptyMonthData;
    }

    const selectedFeatureData = isVideoCategory ? data[dateToShow][selectedVideoCategory] : data[dateToShow][selectedFeature];

    const counts = selectedFeatureData.dailyData.map(item => item.totalClicks);
    const countsDistinct = selectedFeatureData.dailyData.map(item => item.uniqueClicks);
    const labels = selectedFeatureData.dailyData.map(item => item.day);
    const totalMonthCount = selectedFeatureData.monthlyTotals.totalEntries;
    const totalMonthCountDistinct = selectedFeatureData.monthlyTotals.uniqueEntries;

    return {
        counts,
        countsDistinct,
        labels,
        totalMonthCount,
        totalMonthCountDistinct,
    };
}
